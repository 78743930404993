var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"heads"},[_c('div',[_vm._v("用户注册")]),_c('div',[_vm._v("我已经注册，现在就 "),_c('span',{on:{"click":function($event){return _vm.to('/login')}}},[_vm._v("登录")])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"content"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('img',{attrs:{"src":"\thttp://shop.shangjiu-gift.com.cn/Templates/14/images/regist.jpg","alt":""},on:{"click":function($event){}}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-box"},[_c('div',[_c('span',[_vm._v("*")]),_vm._v(" 手机号码:")]),_c('input',{attrs:{"placeholder":"手机号","type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-box"},[_c('div',[_c('span',[_vm._v("*")]),_vm._v(" 名称:")]),_c('input',{attrs:{"type":"text","placeholder":"名称"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-box"},[_c('div',[_c('span',[_vm._v("*")]),_vm._v(" 密码:")]),_c('input',{attrs:{"type":"text","placeholder":"密码"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-box"},[_c('div',[_c('span',[_vm._v("*")]),_vm._v(" 确认密码:")]),_c('input',{attrs:{"type":"text","placeholder":"确认密码"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-box"},[_c('div',[_c('span',[_vm._v("*")]),_vm._v(" 图形验证码:")]),_c('input',{attrs:{"type":"text","placeholder":" 图形验证码"}}),_c('img',{attrs:{"src":"http://shop.shangjiu-gift.com.cn/API/Site/Member/validatecode","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agreement"},[_c('input',{attrs:{"type":"checkbox"}}),_vm._v(" 我已同意并已阅读 "),_c('span',[_vm._v("《用户注册协议》")])])
}]

export { render, staticRenderFns }