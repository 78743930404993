<template>
  <div class="container">
    <div class="heads">
      <div>用户注册</div>
      <div>我已经注册，现在就 <span @click="to('/login')">登录</span></div>
    </div>
    <div class="main">
      <div class="content">
        <div class="input-box">
          <div><span>*</span> 手机号码:</div>
          <input placeholder="手机号" type="text" />
        </div>
        <div class="input-box">
          <div><span>*</span> 名称:</div>
          <input type="text" placeholder="名称" />
        </div>
        <div class="input-box">
          <div><span>*</span> 密码:</div>
          <input type="text" placeholder="密码" />
        </div>
        <div class="input-box">
          <div><span>*</span> 确认密码:</div>
          <input type="text" placeholder="确认密码" />
        </div>
        <div class="input-box">
          <div><span>*</span> 图形验证码:</div>
          <input type="text" placeholder=" 图形验证码" />
          <img
            src="http://shop.shangjiu-gift.com.cn/API/Site/Member/validatecode"
            alt=""
          />
        </div>
        <div class="agreement">
          <input type="checkbox" />
          我已同意并已阅读 <span>《用户注册协议》</span>
        </div>

        <img
          @click=""
          src="	http://shop.shangjiu-gift.com.cn/Templates/14/images/regist.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { http } from "../../../request/http";
export default {
  data() {
    return {
      form: {
        userName: "",
        phone: "",
        password: "",
      },
    };
  },
  methods: {
    to(url) {
      this.$router.push(url);
    },
    regBtn() {
      let reg = this.form;
      http("wxuser.php", {
        fun: "userRegister",
        user_name: reg.userName, // 姓名
        user_phone: reg.phone, // 手机号
        login_password: reg.password, //密码
      }).then((res) => {
        if (res.code == 0) {
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  .heads {
    display: flex;
    align-items: center;
    div {
      &:first-child {
        background: #fff;
        padding: 10px 40px;
        font-size: 14px;
        border: 1px solid #e6e6e6;
        border-bottom: 0;
      }
      &:last-child {
        font-size: 12px;
        flex: 1;
        text-align: right;
        margin-right: 200px;
        span {
          color: red;
          cursor: pointer;
        }
      }
    }
  }
  .main {
    background: #fff;
    border: 1px solid #e6e6e6;
    border-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    .content {
      padding-top: 50px;
      .input-box {
        display: flex;
        // justify-content: center;
        align-items: center;
        font-size: 12px;
        margin-bottom: 40px;
        input {
          border: 1px solid #e6e6e6;
          outline: 0;
          width: 268px;
          height: 28px;
        }
        input::-webkit-input-placeholder {
          /* WebKit browsers*/
          font-size: 12px;
        }
        input:-moz-input-placeholder {
          /* Mozilla Firefox 4 to 18*/
          font-size: 12px;
        }
        input::-moz-input-placeholder {
          /* Mozilla Firefox 19+*/
          font-size: 12px;
        }

        input:-ms-input-placeholder {
          /* Internet Explorer 10+*/
          font-size: 12px;
        }
        div {
          &:nth-child(1) {
            color: #999;
            margin-left: 5px;
            margin-right: 30px;
            width: 100px;
            text-align: right;
            span {
              color: red;
            }
          }
        }
      }
      .agreement {
        font-size: 12px;
        display: flex;
        align-items: center;
        color: #999;
        margin-bottom: 20px;
        span {
          cursor: pointer;
          color: #757373;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>